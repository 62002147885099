import React from 'react';
import { Provider as ProviderRedux } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from './store';
import { ThemeProvider, ThemedGlobalStyle } from '@/theme';
import { RouterProvider } from '@/providers/router.provider';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CredentialProvider } from '@/providers/credential.provider';
import { ThirdwebProvider } from '@thirdweb-dev/react';
import { SUPPORTED_NETWORKS } from '@/config/network.config';
import { ENVS } from '@/config/env.config';
import { ContractProviderContextProvider } from '@/contexts/contract-provider-context';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 24 * 60 * 1000,
      refetchOnWindowFocus: false,
      gcTime: 60 * 24 * 60 * 1000,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: false,
      refetchOnReconnect: false
    }
  }
});

const asyncStoragePersister = createAsyncStoragePersister({
  storage: window.localStorage
});

let persistor = persistStore(store);
export const App = () => {
  console.log(
    `SUPPORTED_NETWORKS[parseInt(ENVS.VITE_BASE_CHAIN_ID?.[0] || '1')]`,
    SUPPORTED_NETWORKS[parseInt(ENVS.VITE_BASE_CHAIN_ID?.[0] || '1')]
  );
  return (
    <ProviderRedux store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <ThemedGlobalStyle />
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
              persister: asyncStoragePersister,
              dehydrateOptions: {
                shouldDehydrateQuery: (query: any) => {
                  return !!query?.meta?.persist;
                }
              }
            }}
          >
            <ThirdwebProvider
              activeChain={SUPPORTED_NETWORKS[parseInt(ENVS.VITE_BASE_CHAIN_ID?.[0] || '1')]}
              autoConnect={true}
              theme={'light'}
            >
              {' '}
              <ContractProviderContextProvider>
                <CredentialProvider>
                  <RouterProvider />

                  <ToastContainer
                    position='top-center'
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnFocusLoss
                    pauseOnHover
                  />
                </CredentialProvider>
              </ContractProviderContextProvider>
            </ThirdwebProvider>
          </PersistQueryClientProvider>
        </ThemeProvider>
      </PersistGate>
    </ProviderRedux>
  );
};
