import { Layout } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Layout)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background: #000321;
  padding-bottom: 213px;
  .slick-track {
    display: flex;
    align-items: stretch;
  }
  .slick-slide {
    display: flex;
    align-items: stretch;
  }
  .slick-slide > div {
    display: flex;
    align-items: stretch;
  }
`;
