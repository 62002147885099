import withConnect from '@/hoc/with-connect';
import { Button } from 'antd';
import React, { memo, useMemo, useState } from 'react';
import Logo from '@/assets/home/Logo.png';
import USDT from '@/assets/home/usdt.png';
import { useBuy } from '../hooks/use-buy';

import { ConnectWallet, useAddress, useBalance } from '@thirdweb-dev/react';
import { toast } from 'react-toastify';
const BuySectionComponent = ({
  account,
  token,
  onSigninWallet,
  currentLaunchPad,
  isStart,
  isEnd,
  privateAllocation
}: any) => {
  console.log('currentLaunchPad', currentLaunchPad);
  const address = useAddress();
  const [amount, setAmount] = useState('');

  const { data: accountUSDTBalance } = useBalance(currentLaunchPad?.saleToken || '');

  console.log('accountUSDTBalance?.value', currentLaunchPad);
  const { handleBuy, isLoading } = useBuy({
    userWallet: address || '',
    tokenAmount: amount,
    launchpad: currentLaunchPad,
    privateAllocation,
    onSuccess: () => {},
    onFailed: () => {}
  });

  const tokenAmountReceiver = useMemo(() => {
    return Number(amount || '0') / Number(currentLaunchPad?.allocationPrice || '1');
  }, [amount, currentLaunchPad]);

  console.log('addres', address);
  return (
    <div className='flex flex-[0.4] flex-col items-center bg-[#141960] rounded-2xl p-6 gap-6 max-[800px]:w-full'>
      <div className='flex items-center justify-center gap-1'>
        <span className='font-medium text-sm text-[#948E9C]'>Price</span>
        <img src={USDT} className='w-4' alt='' />
        <span className='font-semibold text-sm'>{currentLaunchPad?.allocationPrice} USDT</span>
      </div>
      <div className='flex w-full items-center justify-between app-input p-4 rounded-2xl bg-[#00000080] border-[1px] border-[#42467D]'>
        <input
          type='number'
          placeholder='Enter your fund'
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
        <div className='font-medium text-[20px]'>USDT</div>
      </div>
      <div className='flex items-center justify-center gap-1'>
        <span className='font-medium text-sm text-[#948E9C]'>You’ll get</span>
        <span className='font-semibold text-sm'>{tokenAmountReceiver} HYPE </span>
        <img src={Logo} className='w-4' alt='' />
      </div>
      <div className='w-full mt-4'>
        <Button
          className='secondary-button !rounded-[40px]'
          loading={isLoading}
          onClick={async () => {
            if (!token && !!account) {
              onSigninWallet();
              return;
            }
            if (!amount || Number(amount || '0') === 0) {
              toast.dismiss();
              toast.error('Invalid amount');
              return;
            }

            await handleBuy();
          }}
        >
          {account ? (
            token ? (
              'Invest'
            ) : (
              'Sign'
            )
          ) : (
            <div>
              <span>Connect Wallet</span>
              <ConnectWallet className='!opacity-0 !w-full  !h-full !absolute !top-0 !left-0'></ConnectWallet>
            </div>
          )}
        </Button>
      </div>
    </div>
  );
};

export const BuySection = memo(withConnect(BuySectionComponent));
