import React, { memo, useEffect } from 'react';
import { Wrapper } from './header.styled';
import Logo from '@/assets/home/Logo.png';
import { Button } from 'antd';
import { useNavigate } from 'react-router';
import withConnect from '@/hoc/with-connect';
import { ConnectWallet } from '@thirdweb-dev/react';

export const HeaderComponent = ({ account, token, onSigninWallet, isConnectedCompatibleChain }: any) => {
  const navigate = useNavigate();
  useEffect(() => {
    console.log('isConnectedCompatibleChain', isConnectedCompatibleChain);
    if (!isConnectedCompatibleChain) {
      console.log('isConnectedCompatibleChain', isConnectedCompatibleChain);
    }
  }, [isConnectedCompatibleChain]);
  // useEffect(() => {
  //   if (!account && !!token) {
  //     dispatch(deleteAccount());
  //   }
  // }, [account, token]);
  return (
    <Wrapper className='flex flex-col items-center overflow-visible w-full h-[80px] justify-center max-mobile-big:h-[64px]'>
      <div className='w-full max-w-[1136px] flex items-center justify-between px-4'>
        <img
          src={Logo}
          className='w-[56px] max-mobile-big:w-12 cursor-pointer'
          alt=''
          onClick={() => {
            navigate('/');
          }}
        />
        <div className='flex items-center gap-10'>
          <div
            className='font-semibold text-[18px] cursor-pointer hover:opacity-85 transition-all'
            onClick={() => {
              navigate('/ido');
            }}
          >
            IDO Listing
          </div>
          <div className='w-[194px]'>
            <Button
              className='primary-button relative'
              onClick={() => {
                if (!token && !!account) {
                  onSigninWallet();
                  return;
                } else {
                  navigate('/profile');
                }
              }}
            >
              {token ? (
                'Profile'
              ) : account ? (
                'Sign'
              ) : (
                <div>
                  <span>Connect Wallet</span>
                  <ConnectWallet className='!opacity-0 !w-full  !h-full !absolute !top-0 !left-0'></ConnectWallet>
                </div>
              )}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export const Header = memo(withConnect(HeaderComponent));

export default Header;
