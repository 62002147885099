import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useAppDispatch } from '@/app/store';
import { deleteAccount, selectAddress, selectToken, signInByWallet } from '@/redux';
import { useSelector } from 'react-redux';
import { useBalance, useMetamask, useSDK } from '@thirdweb-dev/react';
import { useWalletConnect } from '@thirdweb-dev/react';
import { useDisconnect } from '@thirdweb-dev/react';
import { useNetwork } from '@thirdweb-dev/react';
import { useAddress } from '@thirdweb-dev/react';
import { useChainId } from '@thirdweb-dev/react';
import { useNetworkMismatch } from '@thirdweb-dev/react';
import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import { useQuery } from '@tanstack/react-query';
import { LaunchPadRepository } from '@/repository/launchpad.repository';
import { lowerCacheOptions } from '@/constants/app.constant';
export const withConnect = (WrappedComponent: FunctionComponent) => (props: any) => {
  const { data: nativeBalance } = useBalance(NATIVE_TOKEN_ADDRESS);
  const account = useAddress();
  const connectedAccount = useSelector(selectAddress);
  console.log('connectedAccount', connectedAccount);
  console.log('account', account);
  const sdk = useSDK();
  const onSigninWallet = useCallback(() => dispatch(signInByWallet({ address: account || '', sdk })), [account, sdk]);
  const [{ data, error, loading }, switchNetwork] = useNetwork();
  const token = useSelector(selectToken);
  const disconnect = useDisconnect();
  const connectWithMetamask = useMetamask();
  const connectWithWalletConnect = useWalletConnect();

  const chainId = useChainId();
  console.log('chainId', chainId);
  const isConnectedCompatibleChain = !useNetworkMismatch();
  const connect = (connector: string) => {
    if (connector === 'injected') {
      connectWithMetamask();
      return;
    }
    if (connector === 'walletconnect') {
      connectWithWalletConnect();
      return;
    }
    return;
  };
  const dispatch = useAppDispatch();

  const { data: allLaunchpad } = useQuery({
    queryKey: ['retrieveAllLaunchpad', token],
    queryFn: () => LaunchPadRepository.getAllLaunchpad(),
    retry: false,
    ...lowerCacheOptions,
    refetchOnMount: 'always',
    refetchInterval: 60000
  });

  useEffect(() => {
    if (!!account && !!connectedAccount && account?.toLowerCase() !== connectedAccount?.toLowerCase()) {
      dispatch(deleteAccount());
    }
  }, [account, connectedAccount, dispatch]);

  return (
    <WrappedComponent
      {...{
        ...props,
        connectWithMetamask,
        connectWithWalletConnect,
        account,
        chainId,
        disconnect,
        isConnectedCompatibleChain,
        token,
        connect,
        nativeBalance,
        onSigninWallet,
        allLaunchpad
      }}
    />
  );
};

export default withConnect;
