export const ChainId = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GÖRLI: 5,
  KOVAN: 42,
  MATIC: 137,
  MATIC_TESTNET: 80001,
  FANTOM: 250,
  FANTOM_TESTNET: 4002,
  XDAI: 100,
  BSC: 56,
  BSC_TESTNET: 97,
  ARBITRUM: 42161,
  ARBITRUM_TESTNET: 79377087078960,
  MOONBEAM_TESTNET: 1287,
  AVALANCHE: 43114,
  AVALANCHE_TESTNET: 43113,
  HECO: 128,
  HECO_TESTNET: 256,
  HARMONY: 1666600000,
  HARMONY_TESTNET: 1666700000,
  OKEX: 66,
  OKEX_TESTNET: 65,
  CELO: 42220,
  PALM: 11297108109,
  PALM_TESTNET: 11297108099,
  MOONRIVER: 1285,
  FUSE: 122,
  ZKSYNCERA: 280,
  ZKSYNCERA_MAINNET: 324,
  LUMIA_TESTNET: 1952959480
};
export interface INetworkProps {
  chainId: number;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  shortName: string;
  slug: string;
  testnet: boolean;
  chain: string;
  rpc: string[];
  name: string;
}

export const rpcUrls: {
  [x: number]: string[];
} = {
  [10]: ['https://optimism.llamarpc.com'],
  [97]: [
    'https://data-seed-prebsc-2-s3.binance.org:8545',
    'https://go.getblock.io/ff8d1a45403846e4a8a56da52740a73f',
    'https://bsc-testnet.blastapi.io/51eb83cc-b7d4-4eff-8f93-81913f49ebaf'
  ],
  [42161]: ['https://arb1.arbitrum.io/rpc'],
  [8453]: ['https://mainnet.base.org', 'https://base-rpc.publicnode.com', 'https://base.meowrpc.com'],
  [169]: ['https://pacific-rpc.manta.network/http'],
  [ChainId.MATIC_TESTNET]: ['https://polygon-testnet.blastapi.io/51eb83cc-b7d4-4eff-8f93-81913f49ebaf'],
  [ChainId.MATIC]: ['https://polygon-pokt.nodies.app'],
  [ChainId.LUMIA_TESTNET]: ['https://testnet-rpc.lumia.org']
};
export const SUPPORTED_NETWORKS: { [x: number]: INetworkProps } = {
  [ChainId.LUMIA_TESTNET]: {
    chainId: 1952959480,
    rpc: ['https://testnet-rpc.lumia.org'],
    nativeCurrency: {
      name: 'LUMIA',
      symbol: 'LUMIA',
      decimals: 18
    },
    shortName: 'LUMIA', // Display value shown in the wallet UI
    slug: 'LUMIA', // Display value shown in the wallet UI
    testnet: true, // Boolean indicating whether the chain is a testnet or mainnet
    chain: 'LUMIA', // Name of the network
    name: 'LUMIA Testnet' // Name of the network
  },
  [ChainId.MAINNET]: {
    chainId: 56,
    rpc: ['https://bsc-dataseed.binance.org'],
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18
    },
    shortName: 'BSC', // Display value shown in the wallet UI
    slug: 'BSC', // Display value shown in the wallet UI
    testnet: true, // Boolean indicating whether the chain is a testnet or mainnet
    chain: 'BSC', // Name of the network
    name: 'BSC Testnet' // Name of the network
  },
  [ChainId.ZKSYNCERA]: {
    chainId: 280,
    rpc: ['https://zksync2-testnet.zksync.dev'],
    nativeCurrency: {
      decimals: 18,
      name: 'zkSync Era Testnet',
      symbol: 'zkETH'
    },
    shortName: 'zkSync', // Display value shown in the wallet UI
    slug: 'zkSync', // Display value shown in the wallet UI
    testnet: true, // Boolean indicating whether the chain is a testnet or mainnet
    chain: 'zkSync', // Name of the network
    name: 'zkSync Era Testnet' // Name of the network
  },
  [ChainId.ZKSYNCERA_MAINNET]: {
    chainId: 324,
    rpc: ['https://zksync2-mainnet.zksync.io'],
    nativeCurrency: {
      decimals: 18,
      name: 'zkSync Era',
      symbol: 'zkETH'
    },
    shortName: 'zkSync', // Display value shown in the wallet UI
    slug: 'zkSync', // Display value shown in the wallet UI
    testnet: true, // Boolean indicating whether the chain is a testnet or mainnet
    chain: 'zkSync', // Name of the network
    name: 'zkSync Era' // Name of the network
  },
  [ChainId.GÖRLI]: {
    chainId: 5,
    rpc: ['https://goerli.blockpi.network/v1/rpc/public'],
    nativeCurrency: {
      decimals: 18,
      name: 'GoerliETH',
      symbol: 'GoerliETH'
    },
    shortName: 'Goerli', // Display value shown in the wallet UI
    slug: 'Goerli', // Display value shown in the wallet UI
    testnet: true, // Boolean indicating whether the chain is a testnet or mainnet
    chain: 'Goerli', // Name of the network
    name: 'Goerli' // Name of the network
  }
  // [ChainId.FANTOM]: {
  //   chainId: '0xfa',
  //   chainNumb: ChainId.FANTOM,
  //   chainName: 'Fantom',
  //   nativeCurrency: {
  //     name: 'Fantom',
  //     symbol: 'SOL',
  //     decimals: 18,
  //   },
  //   path: '/phantom.svg',
  //   rpcUrls: ['https://rpcapi.fantom.network'],
  //   blockExplorerUrls: ['https://ftmscan.com'],
  // },
  // [ChainId.BSC]: {
  //   chainId: 56,
  //   rpc: ['https://bsc-dataseed.binance.org'],
  //   nativeCurrency: {
  //     name: 'Binance Coin',
  //     symbol: 'BNB',
  //     decimals: 18,
  //   },
  //   shortName: 'BSC', // Display value shown in the wallet UI
  //   slug: 'BSC', // Display value shown in the wallet UI
  //   testnet: true, // Boolean indicating whether the chain is a testnet or mainnet
  //   chain: 'BSC', // Name of the network
  //   name: 'BSC Testnet', // Name of the network
  // },
  // [ChainId.BSC_TESTNET]: {
  //   chainId: '0x61',
  //   chainName: 'BSC Testnet',
  //   chainNumb: ChainId.BSC_TESTNET,
  //   nativeCurrency: {
  //     name: 'Binance Coin',
  //     symbol: 'BNB',
  //     decimals: 18,
  //   },
  //   // path: '/bsc.svg',
  //   rpcUrls: [''https://data-seed-prebsc-1-s1.binance.org:8545/'],
  //   blockExplorerUrls: ['https://testnet.bscscan.com'],
  // },
  // [ChainId.MATIC]: {
  //   chainId: '0x89',
  //   chainName: 'Matic',
  //   chainNumb: ChainId.MATIC,
  //   nativeCurrency: {
  //     name: 'Matic',
  //     symbol: 'MATIC',
  //     decimals: 18,
  //   },
  //   // path: '/polygon.svg',
  //   rpcUrls: ['https://rpc-mainnet.maticvigil.com'], // ['https://matic-mainnet.chainstacklabs.com/'],
  //   blockExplorerUrls: ['https://explorer-mainnet.maticvigil.com'],
  // },
  // [ChainId.MATIC_TESTNET]: {
  //   chainId: '0x13881',
  //   chainName: 'Mumbai',
  //   chainNumb: ChainId.MATIC_TESTNET,
  //   nativeCurrency: {
  //     name: 'Matic',
  //     symbol: 'MATIC',
  //     decimals: 18,
  //   },
  //   path: '/polygon.svg',
  //   rpcUrls: ['https://rpc.ankr.com/polygon_mumbai'], // ['https://matic-mainnet.chainstacklabs.com/'],
  //   blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
  // },

  // [ChainId.HECO]: {
  //   chainId: '0x80',
  //   chainName: 'Heco',
  //   nativeCurrency: {
  //     name: 'Heco Token',
  //     symbol: 'HT',
  //     decimals: 18,
  //   },
  //   rpcUrls: ['https://http-mainnet.hecochain.com'],
  //   blockExplorerUrls: ['https://hecoinfo.com'],
  // },
  // [ChainId.XDAI]: {
  //   chainId: '0x64',
  //   chainName: 'xDai',
  //   nativeCurrency: {
  //     name: 'xDai Token',
  //     symbol: 'xDai',
  //     decimals: 18,
  //   },
  //   rpcUrls: ['https://rpc.xdaichain.com'],
  //   blockExplorerUrls: ['https://blockscout.com/poa/xdai'],
  // },
  // [ChainId.HARMONY]: {
  //   chainId: '0x63564C40',
  //   chainName: 'Harmony',
  //   nativeCurrency: {
  //     name: 'One Token',
  //     symbol: 'ONE',
  //     decimals: 18,
  //   },
  //   rpcUrls: [
  //     'https://api.harmony.one',
  //     'https://s1.api.harmony.one',
  //     'https://s2.api.harmony.one',
  //     'https://s3.api.harmony.one',
  //   ],
  //   blockExplorerUrls: ['https://explorer.harmony.one/'],
  // },
  // [ChainId.AVALANCHE]: {
  //   chainId: '0xA86A',
  //   chainName: 'Avalanche',
  //   nativeCurrency: {
  //     name: 'Avalanche Token',
  //     symbol: 'AVAX',
  //     decimals: 18,
  //   },
  //   rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
  //   blockExplorerUrls: ['https://cchain.explorer.avax.network'],
  // },
  // [ChainId.OKEX]: {
  //   chainId: '0x42',
  //   chainName: 'OKEx',
  //   nativeCurrency: {
  //     name: 'OKEx Token',
  //     symbol: 'OKT',
  //     decimals: 18,
  //   },
  //   rpcUrls: ['https://exchainrpc.okex.org'],
  //   blockExplorerUrls: ['https://www.oklink.com/okexchain'],
  // },
  // [ChainId.ARBITRUM]: {
  //   chainId: '0xA4B1',
  //   chainName: 'Arbitrum',
  //   nativeCurrency: {
  //     name: 'Ethereum',
  //     symbol: 'ETH',
  //     decimals: 18,
  //   },
  //   rpcUrls: ['https://arb1.arbitrum.io/rpc'],
  //   blockExplorerUrls: ['https://mainnet-arb-explorer.netlify.app'],
  // },
  // [ChainId.CELO]: {
  //   chainId: '0xA4EC',
  //   chainName: 'Celo',
  //   nativeCurrency: {
  //     name: 'Celo',
  //     symbol: 'CELO',
  //     decimals: 18,
  //   },
  //   rpcUrls: ['https://forno.celo.org'],
  //   blockExplorerUrls: ['https://explorer.celo.org'],
  // },
  // [ChainId.PALM]: {
  //   chainId: '0x2A15C308D',
  //   chainName: 'Palm',
  //   nativeCurrency: {
  //     name: 'Palm',
  //     symbol: 'PALM',
  //     decimals: 18,
  //   },
  //   rpcUrls: [
  //     'https://palm-mainnet.infura.io/v3/da5fbfafcca14b109e2665290681e267',
  //   ],
  //   blockExplorerUrls: ['https://explorer.palm.io'],
  // },
  // [ChainId.MOONRIVER]: {
  //   chainId: '0x505',
  //   chainName: 'Moonriver',
  //   nativeCurrency: {
  //     name: 'Moonriver',
  //     symbol: 'MOVR',
  //     decimals: 18,
  //   },
  //   rpcUrls: ['https://rpc.moonriver.moonbeam.network'],
  //   blockExplorerUrls: ['https://blockscout.moonriver.moonbeam.network'],
  // },
};
