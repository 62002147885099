import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import { useChainId, useNetwork, useSDK, useBalance, useAddress } from '@thirdweb-dev/react';
import { useCallback, useState } from 'react';
import { ENVS } from '@/config/env.config';
import { LaunchPadRepository } from '@/repository/launchpad.repository';
import LaunchpadABI from '@/constants/abi/launchpad.json';
import { useWriteTransaction } from '@/hooks/use-write-transaction';
import { useApproveToken } from './use-approve';
import { useCheckApprove } from './use-check-approve';
interface IPropsUseDeposit {
  tokenAmount: string;
  userWallet: string;
  launchpad: any;
  privateAllocation: any;
  onSuccess: () => void;
  onFailed: () => void;
}
export const useBuy = (props: IPropsUseDeposit) => {
  const { data: accountBalance } = useBalance();
  const { tokenAmount, userWallet, launchpad, onSuccess, privateAllocation } = props;
  const { data: accountUSDTBalance } = useBalance(launchpad?.saleToken || '');
  console.log('accountBalance', accountBalance, accountUSDTBalance);
  const sdk = useSDK();
  console.log('launchpad', launchpad);
  const address = useAddress();
  const chainId = useChainId();
  const [, switchNetwork] = useNetwork();
  const [isLoading, setIsLoading] = useState(false);
  const { sendTransaction } = useWriteTransaction();

  const { approval } = useCheckApprove(address || '', launchpad?.saleToken, launchpad?.address);
  const { handleApprove, isLoading: isLoadingApprove } = useApproveToken({
    tokenAmount: accountUSDTBalance?.value || ethers.utils.parseEther(tokenAmount || '0'),
    spender: launchpad?.address,
    saleToken: launchpad?.saleToken,
    onSuccess: () => {},
    onFailed: () => {}
  });
  const handleBuy = useCallback(async () => {
    try {
      if (privateAllocation?.max_allocation && privateAllocation?.allocation_used) {
        const availbleAmount =
          Number(ethers.utils.formatEther(privateAllocation?.max_allocation)) -
          Number(ethers.utils.formatEther(privateAllocation?.allocation_used));
        if (availbleAmount < Number(tokenAmount) && privateAllocation?.allocation_used) {
          toast.dismiss();
          toast.info(`Fund exceed max per account! Available: ${availbleAmount} USDT`);
          return;
        }
      }

      setIsLoading(true);
      const baseChainId = parseInt(ENVS.VITE_BASE_CHAIN_ID?.[0] || '1');
      if (chainId !== baseChainId) {
        toast.dismiss();
        toast.error(`Network mismatched: ${chainId} -> ${baseChainId}`);
        switchNetwork && switchNetwork(baseChainId);
        setIsLoading(false);
        throw new Error('Network mismatched');
      }

      const approvalBigNum = ethers.BigNumber.from(approval?.approval);
      if (approvalBigNum.lt(ethers.utils.parseEther(tokenAmount ?? '0'))) {
        await handleApprove();
      }
      const buyData = {
        launchpad_address: launchpad?.address,
        poolType: privateAllocation ? 'private' : 'public',
        amount: ethers.utils.parseEther(tokenAmount)?.toString()
      };
      console.log('hihih', buyData);
      const buySignature = await LaunchPadRepository.getBuySignature(buyData);
      console.log('signature', buySignature);
      if (accountBalance?.value?.lt(ethers.utils.parseEther('0.01'))) {
        toast.dismiss();
        setIsLoading(false);
        toast.error('Insufficient LUMIA amount for fees!');
        throw new Error('Insufficient LUMIA amount for fees!');
      } else {
        if (accountUSDTBalance?.value?.lt(ethers.utils.parseEther(tokenAmount))) {
          toast.dismiss();
          setIsLoading(false);
          toast.error('Insufficient token amount!');
          throw new Error('Insufficient token amount!');
        } else {
          console.log('!!!!!!!!!!!!');
          const buyTuple = [
            BigInt(buySignature?.values?.queryId),
            buySignature?.values?.buyer,
            BigInt(buySignature?.values?.publicAmount),
            BigInt(buySignature?.values?.privateAmount)
          ];
          console.log('buyTuple', buyTuple, buySignature?.signature);
          const res = await sendTransaction(
            launchpad?.address,
            LaunchpadABI,
            'buy',
            '0',
            buyTuple,
            buySignature?.signature
          );
          // const iface = new ethers.utils.Interface(LaunchpadABI);
          // const _data = [
          //   [
          //     buySignature?.values?.queryId,
          //     buySignature?.values?.buyer,
          //     ethers.utils.parseEther(buySignature?.values?.publicAmount),
          //     BigInt(buySignature?.values?.privateAmount)
          //   ],
          //   buySignature?.signature
          // ];
          // console.log('_data', _data);
          // const data = iface.encodeFunctionData('buy', _data);
          // console.log('data', data);
          // const rawTransaction = {
          //   from: userWallet,
          //   to: '0x0770AcD6303aa0A3523a171B39F2a7Db7B88e583',
          //   value: 0,
          //   data: data,
          //   chainId: Number(ENVS.VITE_BASE_CHAIN_ID?.[0]),
          //   type: 0
          // };
          // console.log('rawTransaction', rawTransaction);
          // const tx = await sdk?.wallet?.sendRawTransaction(rawTransaction);
          // let receipt = await tx?.wait();
          // if (receipt) {
          //   onSuccess();
          // }
          if (res?.success) {
            toast.success('Purchased token successfully!');
          } else {
            toast.dismiss();
            toast.error(res?.data);
          }
          setIsLoading(false);
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      console.log('error', error);
      toast.dismiss();
      toast.error(error?.message || error || 'Something went wrong!');
    }
  }, [
    accountUSDTBalance,
    accountBalance,
    chainId,
    switchNetwork,
    tokenAmount,
    userWallet,
    privateAllocation,
    launchpad,
    sendTransaction
  ]);
  return {
    handleBuy,
    isLoading
  };
};
