import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from '@/modules/homepage';
import IDOPage from '@/modules/ido';
import ProfilePage from '@/modules/profile';

const elements = [
  {
    Component: HomePage,
    path: '/'
  },
  {
    Component: IDOPage,
    path: '/ido'
  },
  {
    Component: ProfilePage,
    path: '/profile'
  }
];

export const RouterProvider = () => {
  return (
    <BrowserRouter>
      <Routes>
        {elements.map(({ Component, path }, idx) => (
          <Route
            key={idx}
            element={
              <React.Suspense fallback={null}>
                <Component />
              </React.Suspense>
            }
            path={path}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};
