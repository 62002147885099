import { useQuery } from '@tanstack/react-query';
import { Token as TokenRepository } from '@/repository/token.repository';
import { useContractProviderContext } from '@/contexts/contract-provider-context';

export const useCheckApprove = (userWallet: string, tokenAddress: string, spender: string) => {
  const { provider } = useContractProviderContext();
  console.log('provider?._network', provider?._network);
  const { data: approval, refetch } = useQuery({
    queryKey: ['getApproval', userWallet, provider?._network],
    queryFn: () => TokenRepository.getApproval(provider, tokenAddress, userWallet, spender, 18),
    enabled: !!provider?._network && !!userWallet,
    retry: false,
    refetchInterval: 10000
  });
  // const shouldApprove = useMemo(() => {
  //   if (!approval?.approval) {
  //     return false;
  //   }
  //   const approvalBigNum = ethers.BigNumber.from(approval?.approval);
  //   return approvalBigNum.lt(amount ?? '0');
  // }, [approval, amount]);
  return {
    approval,
    refetch
  };
};
