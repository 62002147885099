import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

export const useCountDown = (timeClose: number | '', countdownID?: string) => {
  const [timerDays, setTimerDays] = useState('00');
  const [timerHours, setTimerHours] = useState('00');
  const [timerMinutes, setTimerMinutes] = useState('00');
  const [timerSeconds, setTimerSeconds] = useState('00');
  const [countdownDone, setCountdownDone] = useState(false);
  useQuery({
    queryKey: [`countdownInterval${countdownID || ''}`, timeClose, countdownID],
    queryFn: () => {
      if (timeClose === '') {
        return -1;
      }
      const now = new Date(new Date().toUTCString()).getTime();
      const distanceTime = timeClose - now;
      if (!isNaN(distanceTime)) {
        const days: number = Math.floor(distanceTime / (1000 * 60 * 60 * 24));
        const hours: number = Math.floor((distanceTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes: number = Math.floor((distanceTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds: number = Math.floor((distanceTime % (1000 * 60)) / 1000);
        if (distanceTime < 0) {
          setTimerDays('0');
          setTimerHours('0');
          setTimerMinutes('0');
          setTimerSeconds('0');
          setCountdownDone(true);
        } else {
          setCountdownDone(false);
          days < 10 ? setTimerDays('0' + days.toString()) : setTimerDays(days.toString());
          hours < 10 ? setTimerHours('0' + hours.toString()) : setTimerHours(hours.toString());
          minutes < 10 ? setTimerMinutes('0' + minutes.toString()) : setTimerMinutes(minutes.toString());
          seconds < 10 ? setTimerSeconds('0' + seconds.toString()) : setTimerSeconds(seconds.toString());
        }
      }
      return -1;
    },
    retry: false,
    refetchInterval: 1000,
    enabled: timeClose !== ''
  });

  return {
    timerMinutes,
    timerDays,
    timerHours,
    timerSeconds,
    countdownDone
  };
};
