import { Layout } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Layout)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background: #000321;
  padding-bottom: 213px;
  .bar {
    box-shadow: 0px 0px 8px 2px #ffffff;
    background: #ffffff;
  }
  .progress-bar {
    box-shadow: 0px 0px 8px 4px #ffffff;
  }
`;
