import http from '@/utilities/http.utils';
import { BigNumber, ethers } from 'ethers';
import LAUNCHPADABI from '@/constants/abi/launchpad.json';
export class LaunchPadRepository {
  static async getAllLaunchpad(): Promise<any> {
    return http.get('/api/v1/launchpad/all');
  }

  static async getLaunchpadByAddress(address: string): Promise<any> {
    return http.get(`/api/v1/launchpad/${address}`);
  }

  static async getUserAllocation(address: string): Promise<any> {
    return http.get(`/api/v1/launchpad/${address}/allocation`);
  }

  static async getUserResult(buyer: string, launchpad: string): Promise<any> {
    return http.get(`/api/v1/launchpad/result?buyer=${buyer}&launchpad=${launchpad}`);
  }

  static async getUserBuyActivities(address: string): Promise<any> {
    return http.get(`/api/v1/launchpad/${address}/activities`);
  }

  static getBuySignature(rawData: any): Promise<any> {
    return http.post(`/api/v1/launchpad/buy`, rawData);
  }

  static getClaimSignature(rawData: any): Promise<any> {
    return http.get(
      `/api/v1/launchpad/claim?buyer=${rawData?.buyer}&launchpad=${rawData?.launchpad}&is_private=${rawData?.is_private}`
    );
  }

  static getTotalBought(address: string): Promise<any> {
    return http.get(`/api/v1/launchpad/${address}/total`);
  }

  static async getTotalInvested(
    provider: ethers.providers.JsonRpcProvider | undefined,
    address: string
  ): Promise<number> {
    try {
      const NFTcontract = new ethers.Contract(address, LAUNCHPADABI, provider);
      const total: BigNumber = await NFTcontract.totalInvested();
      console.log('total', total);
      return Number(total);
    } catch (error: any) {
      console.log('asd', error);
      return -1;
    }
  }
}
