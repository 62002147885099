import React, { memo, useCallback, useEffect, useState } from 'react';
import { Wrapper } from './profile.styled';
import Header from '@/components/header/header';
import Avatar from '@/assets/avatar.png';
import { ConnectWallet, useAddress } from '@thirdweb-dev/react';
import withConnect from '@/hoc/with-connect';
import { Button } from 'antd';
import { dispatch } from '@/app/store';
import { deleteAccount } from '@/redux';
import { useDisconnect } from '@thirdweb-dev/react';
import { addressSplit } from '@/utilities/string.utils';
import SaleImg from '@/assets/sale.png';
import Logo from '@/assets/home/Logo.png';
import { LaunchPadRepository } from '@/repository/launchpad.repository';
import { useQuery } from '@tanstack/react-query';
import { lowerCacheOptions } from '@/constants/app.constant';
import { ethers } from 'ethers';
const ProfilePageComponent = ({ token, account, onSigninWallet }: any) => {
  const disconnect = useDisconnect();
  const address = useAddress();
  const [userBuyActivities, setUserBuyActivities] = useState<any>([]);
  const { data: allLaunchpad } = useQuery({
    queryKey: ['retrieveAllLaunchpad', token],
    queryFn: () => LaunchPadRepository.getAllLaunchpad(),
    retry: false,
    enabled: !!token,
    ...lowerCacheOptions,
    refetchOnMount: 'always',
    refetchInterval: 60000
  });
  const calcUserBuyActivities = useCallback(async () => {
    await Promise.all(
      allLaunchpad?.map(async (item: any): Promise<any> => {
        if (!item) return;
        const res = await LaunchPadRepository.getUserBuyActivities(item?.address);

        if (res?.length > 0) {
          console.log('res', res);
          const _userBuyActivities = [...userBuyActivities];
          console.log('_userBuyActivities', _userBuyActivities);
          _userBuyActivities.concat(res);
          console.log('_userBuyActivities', _userBuyActivities);
          setUserBuyActivities(res);
        }
      })
    );
  }, [allLaunchpad, token]);
  useEffect(() => {
    calcUserBuyActivities();
  }, [calcUserBuyActivities]);

  console.log('userBuyActivities', userBuyActivities);
  // const { data: userBuyActivities } = useQuery({
  //   queryKey: ['retrieveUserBuyActivities', currentLaunchPad?.address],
  //   queryFn: () => LaunchPadRepository.getUserBuyActivities(currentLaunchPad?.address),
  //   retry: false,
  //   enabled: !!currentLaunchPad?.address,
  //   refetchInterval: 10000
  // });
  return (
    <Wrapper>
      <Header />
      <div className='relative z-[2] flex items-start gap-[64px] max-[990px]:gap-[36px] mt-[152px] w-full max-w-[1136px] px-4 max-mobile:flex-col'>
        <div className='flex flex-col items-start gap-4 flex-[0.3] max-mobile:w-full'>
          <div className='font-bold text-[40px] max-mobile:text-[32px]'>Profile</div>
          <div className='w-full bg-[#070A33] rounded-2xl flex flex-col items-center p-6'>
            <img src={Avatar} className='w-[120px]' alt='' />
            <div className='w-full h-[1px] bg-[#42467D] my-6'></div>
            {account && (
              <div className='w-full flex items-center justify-between'>
                <div className='font-bold text-sm '>Your wallet</div>
                <div
                  className='font-bold text-sm cursor-pointer text-[#31EAFF]'
                  onClick={() => {
                    dispatch(deleteAccount());
                    disconnect();
                  }}
                >
                  Disconnect
                </div>
              </div>
            )}
            <div className='w-full mt-4'>
              {!address ? (
                <Button className='primary-button relative'>
                  <div>
                    <span>Connect Wallet</span>
                    <ConnectWallet className='!opacity-0 !w-full  !h-full !absolute !top-0 !left-0'></ConnectWallet>
                  </div>
                </Button>
              ) : (
                <>
                  <div className='w-full border-[1px] border-[#FFFFFF] p-[10px] rounded-2xl flex items-center justify-center'>
                    <div className='font-normal text-xs text-[#948E9C] flex items-center justify-center text-center mt-[2px]'>
                      Address: {addressSplit(address || '...')}
                    </div>
                  </div>
                  {!token && (
                    <Button
                      className='primary-button relative mt-3'
                      onClick={() => {
                        if (!token && !!account) {
                          onSigninWallet();
                        }
                      }}
                    >
                      Sign
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className='flex flex-col items-start gap-4 flex-[0.7] max-mobile:w-full'>
          <div className='font-bold text-[40px]  max-mobile:text-[32px]'>Investment history</div>
          <div className='w-full bg-[#070A33] rounded-2xl p-6 overflow-auto max-h-[800px]'>
            {userBuyActivities?.length > 0 ? (
              <div className=' flex flex-col items-center gap-4 max-mobile:min-w-[650px]'>
                <div className='w-full flex items-center gap-6'>
                  <div className='font-normal text-sm text-[#999999] flex-[0.25] text-start'>IDO Sales</div>
                  <div className='font-normal text-sm text-[#999999] flex-[0.25] text-center'>Funded</div>
                  <div className='font-normal text-sm text-[#999999] flex-[0.25] text-center'>Token claim</div>
                  <div className='font-normal text-sm text-[#999999] flex-[0.25] text-end'>Date</div>
                </div>
                <div className='w-full h-[1px] bg-[#42467D]'></div>
                {userBuyActivities?.map((item: any, key: number) => {
                  return (
                    <div key={key} className='w-full flex items-center gap-6 '>
                      <div className='flex items-center gap-2 flex-[0.25]'>
                        <img src={SaleImg} className='w-10' alt='' />
                        <div>
                          <div className='font-semibold text-sm'>HyperLaunch</div>
                          <div className='flex items-center gap-1'>
                            <img src={Logo} className='w-6' alt='' />
                            <div className='text-[#948E9C] font-semibold text-sm'>$HYPE</div>
                          </div>
                        </div>
                      </div>
                      <div className='font-normal text-sm flex-[0.25] text-center'>
                        $
                        {Number(ethers.utils.formatEther(item?.public_amount)) +
                          Number(ethers.utils.formatEther(item?.private_amount))}
                      </div>
                      <div className='flex-[0.25] flex items-center gap-2 justify-center'>
                        <div className='font-semibold text-sm'>... HYPE </div>
                        <img src={Logo} className='w-6' alt='' />
                      </div>
                      <div className='font-normal text-sm flex-[0.25] text-end'>
                        {new Date(item?.createdAt)?.toLocaleDateString('en-US')}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className='w-full px-2 py-4 text-center flex items-center justify-center font-bold text-sm'>
                NO HISTORY
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export const ProfilePage = memo(withConnect(ProfilePageComponent));
