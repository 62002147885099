import withConnect from '@/hoc/with-connect';
import { Button } from 'antd';
import React, { memo } from 'react';
import Logo from '@/assets/home/Logo.png';
import { ConnectWallet } from '@thirdweb-dev/react';
import { ethers } from 'ethers';
import USDT from '@/assets/home/usdt.png';
import { useClaim } from '../hooks/use-claim';
import { toast } from 'react-toastify';
const ClaimSectionComponent = ({ account, token, onSigninWallet, currentLaunchPad, type, userResult }: any) => {
  const { handleClaim, isLoading } = useClaim({
    launchpad: currentLaunchPad,
    onSuccess: () => {},
    onFailed: () => {},
    isPrivate: type === 'private'
  });
  return (
    <div className='flex flex-[0.4] flex-col items-center bg-[#141960] rounded-2xl p-6 gap-6 max-[800px]:w-full'>
      <div className='w-full flex items-center justify-between'>
        <div className='font-normal text-sm text-[#948E9C]'>Total $HYPE you receive</div>
        <div className='flex items-center gap-2'>
          <div className='font-semibold text-sm'>
            {Number(ethers.utils.formatEther(userResult?.[`${type}_token_allocation`] || '0'))?.toLocaleString('en', {
              maximumFractionDigits: 5,
              minimumFractionDigits: 0
            })}{' '}
            HYPE{' '}
          </div>
          <img src={Logo} className='w-4' alt='' />
        </div>
      </div>
      {type === 'public' && (
        <div className='w-full flex items-center justify-between'>
          <div className='font-normal text-sm text-[#948E9C]'>Total refund</div>
          <div className='flex items-center gap-2'>
            <div className='font-semibold text-sm'>
              {Number(ethers.utils.formatEther(userResult?.public_bought_refund || '0'))?.toLocaleString('en', {
                maximumFractionDigits: 5,
                minimumFractionDigits: 0
              })}
              {` `}
              USDT
            </div>
            <img src={USDT} className='w-4' alt='' />
          </div>
        </div>
      )}
      <div className='w-full mt-4'>
        <Button
          loading={isLoading}
          className='secondary-button !rounded-[40px]'
          onClick={async () => {
            if (!token && !!account) {
              onSigninWallet();
              return;
            }
            if (type === 'pending') {
              toast.dismiss();
              toast.info('No funding found!');
              return;
            }
            await handleClaim();
          }}
        >
          {account ? (
            token ? (
              'Claim'
            ) : (
              'Sign'
            )
          ) : (
            <div>
              <span>Connect Wallet</span>
              <ConnectWallet className='!opacity-0 !w-full  !h-full !absolute !top-0 !left-0'></ConnectWallet>
            </div>
          )}
        </Button>
      </div>
    </div>
  );
};

export const ClaimSection = memo(withConnect(ClaimSectionComponent));
