import React, { memo, useMemo, useRef, useState } from 'react';
import { Wrapper } from './homepage.styled';
import Header from '@/components/header/header';
import Banner from '@/assets/home/banner.jpg';
import { Button, Carousel, ConfigProvider, Pagination } from 'antd';
import Part1 from '@/assets/home/part1.png';
import Part2 from '@/assets/home/part2.png';
import Part3 from '@/assets/home/part3.png';
import Project1 from '@/assets/home/project1.png';
import Project2 from '@/assets/home/project2.png';
import Logo2 from '@/assets/home/Logo2.png';
import BNB from '@/assets/home/bnb.png';
import Ld1 from '@/assets/home/ld1.png';
import Logo from '@/assets/home/Logo.png';
import Lp1 from '@/assets/home/image 245.png';
import Lp2 from '@/assets/home/image 246.png';
import { useNavigate } from 'react-router';
import withConnect from '@/hoc/with-connect';
import ArrowLeft from '@/assets/arrow-left.png';
import { ethers } from 'ethers';
import { useCountDown } from '@/hooks/use-count-down';
const parts = [Part1, Part2, Part3];
const pageSize = 10;
const HomePageComponent = ({ allLaunchpad }: any) => {
  const [activePage, setActivePage] = useState(0);
  const navigate = useNavigate();
  const CarRef = useRef<any>();
  const currentLaunchPad = useMemo(() => {
    return allLaunchpad?.[0];
  }, [allLaunchpad]);
  const {
    timerDays: startDays,
    timerHours: startHours,
    timerMinutes: startMinutes,
    timerSeconds: startSeconds,
    countdownDone: isStart
  } = useCountDown((currentLaunchPad?.publicStartTime || 0) * 1000, '1');

  const {
    timerDays: endDays,
    timerHours: endHours,
    timerMinutes: endMinutes,
    timerSeconds: endSeconds,
    countdownDone: isEnd
  } = useCountDown((currentLaunchPad?.publicEndTime || 0) * 1000, '2');

  const timeRes = useMemo(() => {
    if (!isStart)
      return {
        timerDays: startDays,
        timerHours: startHours,
        timerMinutes: startMinutes,
        timerSeconds: startSeconds
      };
    return {
      timerDays: endDays,
      timerHours: endHours,
      timerMinutes: endMinutes,
      timerSeconds: endSeconds
    };
  }, [endDays, endHours, endMinutes, endSeconds, startSeconds, startMinutes, startHours, startDays, isStart]);
  return (
    <Wrapper>
      <Header />
      <img src={Banner} className='absolute top-0 left-0 w-full z-[1]' alt='' />
      <div className='relative z-[2] flex flex-col items-start gap-[64px] mt-[152px] w-full max-w-[1136px] px-4'>
        <div className='font-bold text-[72px] max-mobile-xl:text-[56px] max-mobile:text-[48px] max-mobile-sm:text-[40px]  max-w-[560px] w-full'>
          Invest in the Web3 universe
        </div>
        <div className='w-[272px]'>
          <Button
            className='secondary-button'
            onClick={() => {
              navigate('/ido');
            }}
          >
            Go to IDO listing
          </Button>
        </div>
        <div className='w-full flex items-center justify-start gap-10 max-mobile:gap-6'>
          {parts.map((part, key) => {
            return (
              <div key={key} className=''>
                <img src={part} className='h-10 w-auto max-mobile:h-8 max-mobile-sm:h-6' alt='' />
              </div>
            );
          })}
        </div>
        <div className='w-full mt-[152px] flex flex-col items-center max-mobile:mt-[26px]'>
          <div className='font-bold text-[64px] max-mobile-xl:text-[52px] max-mobile:text-[44px] max-mobile-sm:text-[40px] max-mobile:text-center'>
            Available Projects
          </div>
          <div className='w-full flex items-center gap-4 justify-center mt-4'>
            <div
              className={`w-12 h-12 rounded-full bg-[#FFFFFF] cursor-pointer flex items-center justify-center `}
              onClick={() => {
                CarRef?.current?.prev();
              }}
            >
              <img src={ArrowLeft} className='w-6' alt='' />
            </div>
            <div
              className={`w-12 h-12 rounded-full bg-[#FFFFFF] cursor-pointer flex items-center justify-center `}
              onClick={() => {
                console.log('CarRef?.current', CarRef?.current);
                CarRef?.current?.next();
              }}
            >
              <img src={ArrowLeft} className='w-6 rotate-180' alt='' />
            </div>
          </div>
          <div className=' gap-4 w-full mt-6'>
            <Carousel
              ref={CarRef}
              className=''
              dots={false}
              draggable={true}
              slidesPerRow={window.innerWidth > 900 ? 3 : window.innerWidth > 700 ? 2 : 1}
              initialSlide={1}
            >
              <div className='px-2  !flex flex-col items-center'>
                <div className='bg-[#070A33] rounded-2xl overflow-hidden min-h-[368px] flex flex-col w-full h-full  max-[900px]:h-[500px] max-w-[430px]'>
                  <div className='w-full bg-[#23264F] flex items-center justify-center'>
                    <img src={Project1} className='w-full' alt='' />
                  </div>
                  <div className='w-full flex items-center justify-center h-full'>
                    <div className='font-bold text-[#948E9C] text-[24px]'>
                      New project <br />
                      coming soon
                    </div>
                  </div>
                </div>
              </div>
              <div className='px-2  !flex flex-col items-center'>
                <div className='flex-1 bg-[#070A33] rounded-2xl overflow-hidden min-h-[368px] flex flex-col  max-[900px]:h-[500px] max-w-[430px]'>
                  <div className='w-full bg-[#23264F] flex items-center justify-center relative'>
                    <img src={Project2} className='w-full' alt='' />
                    <div className='absolute right-2 bottom-4 flex items-center gap-2'>
                      <div className='bg-[#FFFFFF] rounded-[6px] w-12 h-8 flex items-center justify-center text-center text-xs font-semibold text-[#181E79]'>
                        {timeRes?.timerDays}d
                      </div>
                      <div className='bg-[#FFFFFF] rounded-[6px] w-12 h-8 flex items-center justify-center text-center text-xs font-semibold text-[#181E79]'>
                        {timeRes?.timerHours}h
                      </div>
                      <div className='bg-[#FFFFFF] rounded-[6px] w-12 h-8 flex items-center justify-center text-center text-xs font-semibold text-[#181E79]'>
                        {timeRes?.timerMinutes}m
                      </div>
                      <div className='bg-[#FFFFFF] rounded-[6px] w-12 h-8 flex items-center justify-center text-center text-xs font-semibold text-[#181E79]'>
                        {timeRes?.timerSeconds}s
                      </div>
                    </div>
                    <div className='absolute left-6 bottom-0 translate-y-1/2'>
                      <img src={Logo2} className='w-[56px] ' alt='' />
                    </div>
                  </div>
                  <div className='w-full flex flex-col items-start p-4'>
                    <div className='font-bold text-[24px] mt-3'>HyperLaunch</div>
                    <div className='flex items-center gap-1 mt-2'>
                      <span className='font-normal text-sm text-[#948E9C]'>Token network</span>
                      <img src={BNB} className='w-3' alt='' />
                      <span className='font-semibold text-sm'>BNB chain</span>
                    </div>
                    <div className='font-normal text-sm text-[#948E9C] mt-6'>
                      Token utility includes L1 gas fees and NFT purchase currency for all game-related NFTs. Players
                      can play any game, use the same digital assets in multiple games and other ...
                    </div>
                    <div className='w-full mt-6'>
                      <Button
                        className='secondary-button'
                        onClick={() => {
                          navigate('/ido');
                        }}
                      >
                        In Pre-order
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {(window.innerWidth > 900 || window.innerWidth < 700) && (
                <div className='px-2  !flex flex-col items-center'>
                  <div className='bg-[#070A33] rounded-2xl overflow-hidden min-h-[368px] flex flex-col w-full h-full  max-[900px]:h-[500px] max-w-[430px]'>
                    <div className='w-full bg-[#23264F] flex items-center justify-center'>
                      <img src={Project1} className='w-full' alt='' />
                    </div>
                    <div className='w-full flex items-center justify-center h-full'>
                      <div className='font-bold text-[#948E9C] text-[24px]'>
                        New project <br />
                        coming soon
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Carousel>
          </div>
          <div className='font-bold text-[64px] mt-20 max-mobile-xl:text-[52px] max-mobile:text-[44px] max-mobile-sm:text-[40px] max-mobile:text-center'>
            Perfomance
          </div>
          <div className='w-full mt-4  px-6 max-w-[1136px] overflow-x-auto bg-[#070A33]  rounded-lg py-4 !pr-4'>
            <div className='w-full min-w-[700px] flex flex-col gap-4 max-mobile:p-4  ' id='leaderboard-section'>
              <div className='w-full flex items-center gap-2  pr-4'>
                <div className='flex-[0.24] font-normal text-sm text-[#999999]'>Pool</div>
                <div className='flex-[0.11] font-normal text-sm text-[#999999] text-center'>ATH ROI</div>
                <div className='flex-[0.1625] font-normal text-sm text-[#999999] text-center'>Total raise</div>
                <div className='flex-[0.1625] font-normal text-sm text-[#999999] text-center'>Categories</div>
                <div className='flex-[0.1625] font-normal text-sm text-[#999999] text-center'>Launchpad</div>
                <div className='flex-[0.1625] font-normal text-sm text-[#999999] text-end'>Backers</div>
              </div>
              <div className='w-full h-[1px] bg-[#42467D]'></div>
              <div className='w-full max-h-[700px] overflow-y-auto  flex flex-col gap-4 pr-4 mb-4'>
                {allLaunchpad?.map((item: any, key: number) => {
                  return (
                    <div className='w-full flex items-center gap-2' key={key}>
                      <div className='flex-[0.24] flex items-center gap-2'>
                        <img src={Ld1} className='w-10' alt='' />
                        <div className='flex flex-col items-start'>
                          <div className='font-semibold text-sm'>HyperLaunch</div>
                          <div className='flex items-center gap-1'>
                            <img src={Logo} className='w-6' alt='' />
                            <div className='font-semibold text-[#948E9C] text-sm'>$HYPE</div>
                          </div>
                        </div>
                      </div>
                      <div className='flex-[0.11]  font-normal text-sm truncate text-center'>x92</div>
                      <div className='flex-[0.1625] text-center  font-normal text-sm '>
                        $
                        {(
                          Number(ethers.utils.formatEther(item?.privateAmount || '0')) +
                          Number(ethers.utils.formatEther(item?.publicAmount || '0'))
                        )?.toLocaleString('en', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 0
                        })}
                      </div>
                      <div className='flex-[0.1625] font-normal text-sm text-center'>AI, Layer 2</div>
                      <div className='flex-[0.1625] flex items-center gap-2 justify-center'>
                        <img src={Lp1} className='w-8' alt='' />
                        <img src={Lp2} className='w-8' alt='' />
                      </div>
                      <div className='flex-[0.1625] font-normal text-end text-sm'>Backer</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className='w-full flex items-center justify-center mt-4'>
            <ConfigProvider
              theme={{
                components: {
                  Pagination: {
                    itemActiveBg: '#000321',
                    colorText: '#FFFFFF',
                    colorPrimaryTextActive: '#FFFFFF'
                  }
                }
              }}
            >
              <Pagination
                defaultCurrent={1}
                total={Math.min(0, 100)}
                className='text-[#FFFFFF] !border-none ld-pagination'
                pageSize={pageSize}
                current={activePage}
                onChange={(e) => {
                  setActivePage(e);
                }}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export const HomePage = memo(withConnect(HomePageComponent));
