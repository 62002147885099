import { rpcUrls } from '@/config/network.config';
import { ethers } from 'ethers';
import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import useLocalStorage from 'use-local-storage';
import { ENVS } from '@/config/env.config';
import { Token } from '@/repository/token.repository';

export interface IContractProviderContext {
  provider?: ethers.providers.JsonRpcProvider;
  changeProvider: () => void;
}

const ContractProviderContext = React.createContext<IContractProviderContext | null>(null);
export const ContractProviderContextProvider = ({ children }: React.PropsWithChildren) => {
  const [activeProvider, setActiveProvider] = useLocalStorage('activeRpcProvider', 0);
  const changeProvider = React.useCallback(() => {
    if (activeProvider < rpcUrls[parseInt(ENVS.VITE_BASE_CHAIN_ID?.[0] || '10')]?.length - 1) {
      setActiveProvider(activeProvider + 1);
    } else {
      setActiveProvider(0);
    }
  }, [activeProvider, setActiveProvider]);
  const provider = React.useMemo(() => {
    const _provider = new ethers.providers.JsonRpcProvider(
      rpcUrls[parseInt(ENVS.VITE_BASE_CHAIN_ID?.[0] || '10')]?.[activeProvider]
    );
    return _provider;
  }, [activeProvider]);

  useQuery({
    queryKey: ['checkRpcStatus', activeProvider],
    queryFn: () => Token.checkProviderStatus(provider, changeProvider),
    retry: false,
    enabled: !!provider,
    refetchInterval: false
  });
  return (
    <ContractProviderContext.Provider
      value={{
        provider,
        changeProvider
      }}
    >
      {children}
    </ContractProviderContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useContractProviderContext = (): IContractProviderContext => {
  const context = React.useContext(ContractProviderContext);
  if (context === undefined) {
    throw new Error('useNotificationContext Error');
  }
  return context as IContractProviderContext;
};
